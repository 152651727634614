import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import moment from "moment";
import { config } from "app/config/main";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { ToastServices } from "app/services/toastServices";
import { IoArrowBack } from "react-icons/io5";
import { GoArrowSwitch } from "react-icons/go";
import { LuUpload } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";
import { GrPowerReset } from "react-icons/gr";
import { FaRegCirclePlay } from "react-icons/fa6";
import { DateSvg, EventLocationSvg } from "app/assets/svg";
import { NO_GALLARY } from "app/assets/index";
import UploadGallary from "./UploadGallary";
import LoaderComp from "app/components/loader/Loader";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";

const ManageGallary = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pageloader, setPageLoader] = useState(false);
  const [viewGallery, setViewGallery] = useState({});
  const getToken = sessionStorage.getItem("token");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const EncryptToken = Encrytion(getToken);
  const [images, setImages] = useState([]);
  const [reorderArray, setReorderArray] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState(id);
  const [reOderEnable, setReOderEnable] = useState(true);
  const [reset, SetRest] = useState(false);
  const [count, setGetCount] = useState(0)

  //View Gallery
  const getViewGallery = async () => {
    setPageLoader(true);
    const headers = {
      Authorization: `Bearer ${EncryptToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.get(
        `${config.apiUrl}/events/gallery/view/${id}`,
        {
          headers,
        }
      );
      if (!response.status === true) {
        ToastServices.ErroToast("Something went wrong");
        setPageLoader(false);
      }
      setViewGallery(response?.data?.data);
      setPageLoader(false);
    } catch (e) {
      console.log(e);
    }
  };

  // delete
  const handleDelete = async (id) => {
    setSelectedIndex(null);
    deleteAction(id);
  };

  const deleteAction = async (id) => {
    const userData = {
      id: id,
    };
    const options = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${EncryptToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    };
    try {
      await fetch(`${config.apiUrl}/events/gallery/delete/${id}`, options)
        .then((res) => res.json())
        .then((res) => {
          if (res?.status === true) {
            ToastServices.SucessToast(res?.message);
            getViewGallery();
          } else if (res?.status === false) {
            ToastServices.ErroToast(res?.message);
            setLoading(false);
          } else if (res?.statusCode === 429) {
            setLoading(false);
            ToastServices.ErroToast("Something went wrong");
          } else if (res?.statusCode === 500) {
            ToastServices.ErroToast("Something went wrong");
            setLoading(false);
          } else if (res?.statusCode === 404) {
            ToastServices.ErroToast("Something went wrong");
            setLoading(false);
          }
        });
    } catch (err) {
      setLoading(false);
      ToastServices.ErroToast("Something went wrong");
    }
  };

  // upload gallery
  const handleClickOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  const reorderGallery = async () => {
    setPageLoader(true);
    setReOderEnable(true);
    SetRest(false);
    const imgData = {
      sort_order: reorderArray,
    };

    const headers = {
      Authorization: `Bearer ${EncryptToken}`,
      "Content-Type": "application/json",
    };

    try {
      const reorderResponse = await axios.put(
        `${config.apiUrl}/events/gallery/reorder-update`,
        imgData,
        {
          headers,
        }
      );
      if (reorderResponse?.data?.status === true) {
        setPageLoader(false);
        ToastServices.SucessToast(reorderResponse?.data?.message);
      } else if (reorderResponse?.data?.status === false) {
        ToastServices.ErroToast(reorderResponse?.data?.message);
        setPageLoader(false);
      } else if (reorderResponse?.data?.statusCode === 429) {
        setPageLoader(false);
        ToastServices.ErroToast("Something went wrong");
      } else if (reorderResponse?.data?.statusCode === 500) {
        ToastServices.ErroToast("Something went wrong");
        setPageLoader(false);
      } else if (reorderResponse?.data?.statusCode === 404) {
        ToastServices.ErroToast("Something went wrong");
        setPageLoader(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // reorder and reset

  const handleReorder = () => {
    setReOderEnable(false);
    SetRest(true);
   const beforeRest = images?.map((item) => item.id);
      setReorderArray(beforeRest);

  };

  const handleReSet = () => {
    setReOderEnable(true);
    SetRest(false);

    getViewGallery();
  };

  // fetch gallery data
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getViewGallery();
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {
    if (viewGallery[0]?.eventsgallery) {
      setImages(viewGallery[0].eventsgallery);
      setGetCount(viewGallery[0].eventsgallery?.length)
      
    }
  }, [viewGallery]);

  const handleChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    if (targetId !== undefined) {
      // Handle move
      const result = move(
        images[sourceIndex],
        images[targetIndex],
        sourceIndex,
        targetIndex
      );
      const newImages = [...images];
      [newImages[sourceIndex], newImages[targetIndex]] = result;
      setImages(newImages);
    } else {
      // Handle swap
      const result = swap(images, sourceIndex, targetIndex);
      setImages(result);
     
      const newIdArray = result?.map((item) => item.id);
      setReorderArray(newIdArray);
    }
  };

  const getDynamic = () => {
    const setHeight = Math.ceil(images?.length / 7) * 240; /// Add the rwo height and box-count

    return setHeight;
  };

  const handleCloseClick = (event, id) => {
    event.stopPropagation();
    handleDelete(id);
  };

  
  const  handleMangeClick =  (e, url) =>{
    e.stopPropagation();
    window.open(`${url}`, '_blank');

  }
  // Reorder

  return (
    <Fragment>
      <div className="form_header">
        <Typography variant={"h1"} mb={3} mt={1} fontWeight={600}>
          {"Manage Gallery"}
        </Typography>
        <div>
          <Button
            variant="outlined"
            className="outlined_btn ml-12"
            onClick={() => handleClickOpen(viewGallery[0]?.id)}
          >
            {" "}
            <LuUpload fontSize={18} className="mr-5" /> Upload Gallery
          </Button>
          { count !== 0 &&  !reset &&  (
            <Button
              variant="outlined"
              className="outlined_btn ml-12"
              onClick={handleReorder}
            >
              {" "}
              <GoArrowSwitch fontSize={18} className="mr-5" /> Re-Order
            </Button>
          )}
      
          {reset && (
            <Button
              variant="outlined"
              className="outlined_btn ml-12"
              onClick={handleReSet}
            >
              <GrPowerReset fontSize={18} className="mr-5" /> ReSet
            </Button>
          )}

          <Button
            variant="outlined"
            className="outlined_btn ml-12"
            onClick={() => navigate("/manage-events")}
          >
            {" "}
            <IoArrowBack fontSize={18} className="mr-5" /> Back
          </Button>
        </div>
      </div>

      <Card sx={{ padding: "12px" }}>
        <CardContent>
          <div>
            <h2 className="job_title">{viewGallery[0]?.eventTitle}</h2>
            <div className="job_keys">
              <div className="job_keys_cont">
                <DateSvg />
                <p>
                  {moment(viewGallery[0]?.eventDate).format(
                    "dddd, DD MMMM, YYYY"
                  )}
                </p>
              </div>
              <div className="job_keys_cont">
                <EventLocationSvg />
                <p>{viewGallery[0]?.eventLocation}</p>
              </div>
            </div>

            {images && images?.length > 0 ? (
              <GridContextProvider onChange={handleChange} >
                <GridDropZone
                  id="items"
                  boxesPerRow={7}
                  rowHeight={240}
                  disableDrag={reOderEnable}
                  className="gallery_grid"
                  style={{
                    height: `${getDynamic()}px`,
                  }}
                >
                  {images.map((item, index) => (
                    <GridItem key={item.id || index} className= {reset == true ?   "griditemUI cursor_change" : "griditemUI" }   >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div
                          className="new_gallery_bg"
                          style={{
                            backgroundImage: `url(${
                              item?.type === 1
                                ? item?.imagesUrl
                                : item?.videoThumbnailImagesUrl
                            })`,
                          }}
                        >
                          {item.type === 2 ? (
                            <div className="video_thumb">
                              <span className="overlay">
                                <FaRegCirclePlay className="play_icon" />
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="close-icon">
                            <IoMdClose
                              onClick={(event) =>
                                handleCloseClick(event, item?.id)
                              }
                              fontSize={"18px"}
                            />
                          </div>
                        </div>
                        <div className="quick-view">
                          <p onClick={(e)=>handleMangeClick(e, item?.imagesUrl)}>Preview</p>
                        </div>
                      </div>
                    </GridItem>
                  ))}
                </GridDropZone>
              </GridContextProvider>
            ) : (
              <div className="no_gallary">
                <div className="no_data_message">
                  <img src={NO_GALLARY} alt="no gallary image" />
                  <p>No Gallery Images</p>
                  <LoadingButton
                    className="filter_bttn"
                    variant="contained"
                    fullWidth
                    style={{ marginRight: "10px", width: 250 }}
                    onClick={() => handleClickOpen(viewGallery[0]?.id)}
                  >
                    Upload Gallery
                  </LoadingButton>
                </div>
              </div>
            )}

            {!reOderEnable &&  (
              <div className="submit-btn-sec">
                <LoadingButton
                  className="filter_bttn"
                  variant="contained"
                  fullWidth
                  style={{ marginRight: "10px", width: 150 }}
                  onClick={reorderGallery}
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
      <UploadGallary
        open={open}
        id={selectedId}
        onClose={handleClose}
        dialogOpened={setOpen}
        getViewGallery={getViewGallery}
      />
      {pageloader === true ? <LoaderComp /> : null}
    </Fragment>
  );
};

export default React.memo(ManageGallary);
