import React, { Fragment, useContext, useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import {
  CardContent,
  Card,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Box,
  Drawer,
  Pagination,
  Stack,
  Menu,
  MenuItem,
  Fade,
  Button,
  IconButton,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GetAppIcon from "@mui/icons-material/GetApp";
import CachedIcon from "@mui/icons-material/Cached";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik, useFormik } from "formik";
import debounce from "lodash/debounce";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Encrytion } from "app/utils/EncryptionHelpers";
import { Search, SearchIconWrapper, StyledInputBase } from "./style";
import { AuthenticationContext } from "app/utils/constants/AuthContext";
import NoRecordsFound from "../manage-jobs/NoRecordsFound";
import { config } from "app/config/main";
import UpdateStatus from "./updateStatus";
import LoaderComp from "app/components/loader/Loader";
import { ToastServices } from "app/services/toastServices";
import * as yup from "yup";
import { logoutAction } from "app/redux/actions/authAction";

const ManageEnquiry = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const getToken = sessionStorage.getItem("token");
  const decrpttoken = Encrytion(getToken);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [updateId, setUpdateId] = useState();
  const [enqData, setenqData] = useState({
    country: "",
    status: "",
    toDate: "",
    fromDate: "",
    search: "",
    limit: "10",
    offset: "1",
    sortByType: "",
    sortByKey: "",
  });
  const [country, setCountry] = useState();
  const [status, setStatus] = useState();
  const [sortType, setSortType] = useState("ASC");
  const [sortKey, setSortKey] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isloading, setisLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [dateopen, setDateOpen] = useState(false);
  const [todateOpen, setTodateOpen] = useState(false);
  const [statusCode, setStatusCode] = useState(false);

  const loadData = async () => {
    setLoading(true);
    // Merge userData with existing enqData state to retain previous values
    const mergedData = { ...enqData };

    // Construct the API request URL based on the merged data
    const {
      country,
      status,
      toDate,
      fromDate,
      search,
      limit,
      offset,
      sortByType,
      sortByKey,
    } = mergedData;
    let api = `${config.apiUrl}/enquiry/admin?`;

    // Add parameters to the API request URL based on the provided data
    if (country) api += `&country=[${country}]`;
    if (status) api += `&status=[${status}]`;
    if (toDate) api += `&toDate=${toDate}`;
    if (fromDate) api += `&fromDate=${fromDate}`;
    if (search) api += `&searchWith=${search}`;
    if (limit) api += `&limit=${limit}`;
    if (offset) api += `&offset=${offset}`;
    if (sortByType) api += `&sortByType=${sortByType}`;
    if (sortByKey) api += `&sortByKey=${sortByKey}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${decrpttoken}`,
      },
    };

    await fetch(api, options)
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        setCount(res?.status ? res.count : 0);
        // setPage(((res?.status)?res.totalPages:0))
        setTotalPage(res?.status ? res.totalPages : 0);
        setLoading(false);

        if (res.statusCode === 401) {
          navigate("/");
          logoutAction();
          setStatusCode(true);
        }
      });
  };
  const loadStatus = async () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch(`${config.apiUrl}/status`, options)
      .then((res) => res.json())
      .then((res) => {
        setStatus(res.data);
      });
  };

  const loadCountry = async () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type":
          "application/json" /*  'Authorization': `Bearer ${decrpttoken}` */,
      },
    };
    await fetch(`${config.apiUrl}/country`, options)
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.data);
      });
  };
  const { isAuthenticated } = useContext(AuthenticationContext);
  useEffect(() => {
    const fetchDataWithDelay = async () => {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await loadStatus();
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await loadCountry();
    };
    if (isAuthenticated) {
      fetchDataWithDelay();
    }
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      loadData();
    }
    if (statusCode === true) {
      navigate("/");
      logoutAction();
    }
  }, [enqData]);
  const loadExcel = async () => {
    const mergedData = { ...enqData };

    const {
      country,
      status,
      toDate,
      fromDate,
      search,
      limit,
      offset,
      sortByType,
      sortByKey,
    } = mergedData;
    let api = `${config.apiUrl}/enquiry/excel?`;

    // Add parameters to the API request URL based on the provided data
    if (country) api += `&country=[${country}]`;
    if (status) api += `&status=[${status}]`;
    if (toDate) api += `&toDate=${toDate}`;
    if (fromDate) api += `&fromDate=${fromDate}`;
    if (search) api += `&searchWith=${search}`;
    if (limit) api += `&limit=${limit}`;
    if (offset) api += `&offset=${offset}`;
    if (sortByType) api += `&sortByType=${sortByType}`;
    if (sortByKey) api += `&sortByKey=${sortByKey}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${decrpttoken}`,
      },
    };

    await fetch(api, options)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        const moment = require("moment");
        // Get the current date and time
        const currentDate = moment();
        // Format the date and time
        const formattedDateTime = currentDate.format("DD_MM_YYYY");
        link.setAttribute("download", `Enquiry_${formattedDateTime}.xlsx`);
        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(url);
        document.body.removeChild(link);
        ToastServices.SucessToast("Excel file had downloaded successfully");
      })
      .catch((error) => {
        // Handle errors
        ToastServices.ErroToast("Error downloading Excel file");
      });
  };

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  function handleDrawerClose() {
    setMobileOpen(false);
  }

  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(i);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const handleStatus = (id) => {
    handleDrawerToggle();
    setUpdateId(id);
    handleClose();
  };

  const handleView = (id) => {
    navigate(`/manage-enquiry/view/${id}`);
    handleClose();
  };

  const handleFilter = () => {
    handleFilterToggle();
    handleClose();
  };

  const handleExport = () => {
    let data = {
      search: searchInput ? searchInput : "",
      sortByType: sortType ? sortType : "",
      sortByKey: sortKey ? sortKey : "",
      limit: rowsPerPage,
      offset: page,
    };
    data = { ...data, ...filterData };
    // console.log(data)
    loadExcel({ data });
  };

  // Debounce the handleSearchChange function
  const debouncedSearch = debounce((input) => {
    setLoading(true);
    const lowerCaseInput = input.toLowerCase();
    setenqData({ ...enqData, search: input });
    // debouncedLoadData({search: input})
    setLoading(false);
  }, 5000);

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);

    let input = event.target.value.toLowerCase();
    // Call the debounced function with the search input
    debouncedSearch(input);
  };

  useEffect(() => {
    debouncedSearch(searchInput);
    return () => {
      // Cleanup the debounce function on component unmount
      debouncedSearch.cancel();
    };
  }, [searchInput]);

  const handleSort = (column) => {
    //const newSortKey = sortType === column ? (sortKey === 'ASC' ? 'DESC' : 'ASC') : 'ASC';
    const newSorttype =
      sortKey === column && sortType === "ASC" ? "DESC" : "ASC";
    setSortType(newSorttype);
    setSortKey(column);
    //loadData({ sortByType: newSorttype, sortByKey: column });
    setenqData({
      ...enqData,
      sortByType: newSorttype,
      sortByKey: column,
      offset: 1,
    });
  };

  const validationSchema = yup.object().shape({
    toDate: yup.date().nullable(),
    fromDate: yup
      .date()
      .nullable()
      .when(["toDate"], (toDate, schema) => {
        return toDate ? schema.required("From Date is required") : schema;
      }),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      country: "",
      status: "",
      toDate: null,
      fromDate: null,
    },

    onSubmit: async (values) => {
      handleSubmit(values);

      // formik.resetForm();
      handleFilterClose();
    },
  });

  const handleSubmit = (values) => {
    setisLoading(true);
    let arr = values?.country ? [values?.country] : [];

    const userData = {
      country: arr.length === 0 ? "" : arr,
      status: values?.status ? values?.status : "",
      toDate: !values?.toDate
        ? ""
        : moment(values?.toDate).format("YYYY-MM-DD"),
      fromDate: !values?.fromDate
        ? ""
        : moment(values?.fromDate).format("YYYY-MM-DD"),
      offset: "",
    };

    setFilterData(userData);
    setenqData({ ...enqData, ...userData });
    ToastServices.SucessToast("Filter reset successfully");

    setisLoading(false);
  };

  function handleFilterToggle() {
    setFilterOpen(!filterOpen);
  }
  function handleFilterClose() {
    setFilterOpen(false);
    //formik.resetForm();
    // console.log(filterData, 'filterData')
  }

  const handleReset = () => {
    formik.resetForm();
    setenqData({
      country: "",
      status: "",
      toDate: "",
      fromDate: "",
      search: "",
      limit: "",
      offset: "",
      sortByType: "",
      sortByKey: "",
    });
    setSearchInput("");
    // Clear the input value in the DOM
    const inputElement = document.querySelector(".search input");
    if (inputElement) {
      inputElement.value = "";
    }
    ToastServices.SucessToast("Filter reset successfully");
  };

  const handleClear = () => {
    const initialValues = formik.initialValues;
    const currentValues = formik.values;

    // Check if any of the current values differ from the initial values
    const shouldReset = Object.keys(currentValues).some(
      (key) => currentValues[key] !== initialValues[key]
    );

    // Reset formik values only if they differ from initial values
    if (shouldReset) {
      formik.resetForm();
      handleReset();
      setSearchInput("");
      // Clear the input value in the DOM
      const inputElement = document.querySelector(".search input");
      if (inputElement) {
        inputElement.value = "";
      }
    }
    handleFilterClose();
    //  ToastServices.SucessToast("Data reset successfully")
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function CheckReminder(date, status) {
    const today = formatDate(new Date());
    const name =
      date === today && status === 2
        ? "notification-icon-animated alert-icon"
        : "alert-icon";
    return name;
  }

  const handlePaginationChange = (event, page) => {
    setPage(page);
    setenqData({ ...enqData, offset: page });
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setPage(1);
    setenqData({ ...enqData, limit: event.target.value,  offset: 1});
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "status") {
      setSelectedStatus(value);
    } else if (name === "country") {
      setSelectedCountry(value);
    }
    formik.handleChange(event);
  };

  const handleClearSearch = () => {
    if (searchInput != "") {
      setSearchInput("");
      // Clear the input value in the DOM
      const inputElement = document.querySelector(".search input");
      if (inputElement) {
        inputElement.value = "";
      }
      setenqData({ ...enqData, serach: "" });
    }
  };
  return (
    <Fragment>
      <Div className="div_container d-flex justify-content space-between">
        <div className="d-flex justify-content-start flex-start">
          <div className="mt-10">
            <Typography
              className="heading"
              variant={"h2"}
              mb={5}
              fontWeight={600}
            >{`Enquiries (${count})`}</Typography>
          </div>
          <div className="">
            {/** <SearchGlobal loadData={loadData} data={data} /> */}
            <Search className="search">
              <SearchIconWrapper>
                <SearchIcon className="search-icon" />
              </SearchIconWrapper>

              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchChange}
                endAdornment={
                  searchInput && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch}>
                        <ClearIcon fontSize="12" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </Search>
          </div>
        </div>

        <div className="d-flex justify-content-end flex-end">
          <div className="ml-20 ">
            <Button
              variant="outlined"
              className="outlined_btn"
              onClick={handleFilter}
            >
              <FilterListIcon className="mr-5" />
              Filter
            </Button>
          </div>
          <div className="ml-20  ">
            <Button
              variant="outlined"
              className="outlined_btn"
              onClick={handleReset}
            >
              <CachedIcon className="mr-5" />
              Reset
            </Button>
          </div>
          <div className="ml-20 ">
            <Button
              variant="outlined"
              className="outlined_btn"
              onClick={handleExport}
            >
              <GetAppIcon className="mr-5" />
              Export
            </Button>
          </div>
        </div>
      </Div>
      <Card className="responsive_card">
        <CardContent>
          <Div>
            <Table className="common_table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "createdAt"}
                      direction={sortType}
                      onClick={() => handleSort("createdAt")}
                    >
                      Date{" "}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "enquiryId"}
                      direction={sortType}
                      onClick={() => handleSort("enquiryId")}
                    >
                      Enquiry ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "name"}
                      direction={sortType}
                      onClick={() => handleSort("name")}
                    >
                      Name{" "}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "enquiry_email"}
                      direction={sortType}
                      onClick={() => handleSort("enquiry_email")}
                    >
                      Email ID{" "}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "mobile"}
                      direction={sortType}
                      onClick={() => handleSort("mobile")}
                    >
                      Mobile{" "}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "company_name"}
                      direction={sortType}
                      onClick={() => handleSort("company_name")}
                    >
                      Company{" "}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "country"}
                      direction={sortType}
                      onClick={() => handleSort("country")}
                    >
                      Country{" "}
                    </TableSortLabel>{" "}
                  </TableCell>
                  <TableCell>Message </TableCell>
                  <TableCell>Alert</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <>
                    <LoaderComp />
                    
                  </>
                ) 
                
                 }
                
                 {
                  data?.map((item, i) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {moment(item.createdAt).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          <Link
                            className="link"
                            to={`/manage-enquiry/view/${item.id}`}
                          >
                            {item.enqId}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {item?.name.length > 15 ? (
                            <>
                              {item?.name.slice(0, 12)}
                              <Tooltip title={item?.name}>
                                <span className="cursor blue_txt">{`...`}</span>
                              </Tooltip>
                            </>
                          ) : (
                            <span>{item?.name}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {item?.email.length > 15 ? (
                            <>
                              {item?.email.slice(0, 12)}
                              <Tooltip title={item?.email}>
                                <span className="cursor blue_txt">{`...`}</span>
                              </Tooltip>
                            </>
                          ) : (
                            <span>{item?.email}</span>
                          )}
                        </TableCell>
                        <TableCell>{item.mobileNo}</TableCell>
                        <TableCell>
                          {item?.companyName?.length > 15 ? (
                            <>
                              {item?.companyName.slice(0, 12)}
                              <Tooltip title={item?.companyName}>
                                <span className="cursor">{`...`}</span>
                              </Tooltip>{" "}
                            </>
                          ) : (
                            <span>{item?.companyName}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {item?.country?.name?.length > 13 ? (
                            <>
                              {item?.country?.name.slice(0, 10)}
                              <Tooltip title={item?.country?.name}>
                                <span className="cursor blue_txt">{`...`}</span>
                              </Tooltip>{" "}
                            </>
                          ) : (
                            <span>{item?.country?.name}</span>
                          )}
                        </TableCell>

                        <TableCell>
                          {item?.message.length > 20 ? (
                            <>
                              {item?.message.slice(0, 17)}
                              <Tooltip title={item?.message}>
                                <span className="cursor blue_txt">{`...`}</span>
                              </Tooltip>
                            </>
                          ) : (
                            <span className="">{item?.message}</span>
                          )}
                        </TableCell>

                        <TableCell>
                          <IconButton>
                            <NotificationsIcon
                              className={CheckReminder(
                                item?.followUpDate,
                                item?.status?.id
                              )}
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {item?.status?.id === 1 ? (
                            <p className="p-pending status">New</p>
                          ) : item?.status?.id === 2 ? (
                            <p className="p-followup status">Follow Up</p>
                          ) : item?.status?.id === 3 ? (
                            <p className="p-active status">Interested</p>
                          ) : (
                            <p className="p-inactive status">Not Interested</p>
                          )}
                        </TableCell>

                        <TableCell>
                          <IconButton
                          className="p-0"
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            // onClick={(e) => handleClick(e, item)}
                            onClick={(event) => handleClick(event, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                        <Menu
                          className="custom_popover"
                          id="fade-menu"
                          MenuListProps={{
                            "aria-labelledby": "fade-button",
                          }}
                          open={selectedIndex === i}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          <MenuItem onClick={() => handleStatus(item.id)}>
                            Update Status
                          </MenuItem>

                          <MenuItem onClick={() => handleView(item?.id)}>
                            View
                          </MenuItem>
                        </Menu>
                      </TableRow>
                    );
                  })
                }
                  {data?.length === 0 && ( <NoRecordsFound tablecell={11} />) }
              </TableBody>
            </Table>


            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <div className="count_page">
                <span className="mr-10">Items per page :</span>
                <select
                  className="pagination_selectbox"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value="10">10</option>
                  <option value="20">25 </option>
                  <option value="30">50 </option>
                </select>
              </div>
              <Stack spacing={2}>
                <Pagination
                  count={totalPage}
                  page={page}
                  onChange={handlePaginationChange}
                />
              </Stack>
            </div>
          </Div>
        </CardContent>
      </Card>

      <div>
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={filterOpen}
          onClose={handleFilterToggle}
        >
          <Box sx={{ width: "400px" }}>
            <div className="sidebar_header">
              <h1> Filter </h1>
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={handleFilterClose}
              />
            </div>

            <div className="sideform_container">
              <form onSubmit={formik.handleSubmit}>
                <Div>
                  <Typography className="from_label">From Date</Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      name="fromDate"
                      className="form_new_style"
                      value={formik.values.fromDate}
                      open={dateopen}
                      slotProps={{
                        textField: { onClick: () => setDateOpen(true) },
                      }}
                      onClose={() => setDateOpen(false)}
                      onChange={(newDate, event) => {
                        setFromDate(newDate);
                        formik.setFieldValue("fromDate", newDate);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      disableFuture
                      format="dd/MM/yyy"
                    />
                    {formik.touched.fromDate && formik.errors.fromDate ? (
                      <Typography color="error">
                        {formik.errors.fromDate}
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Div>

                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">To Date</Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className="form_new_style"
                      name="toDate"
                      value={formik.values.toDate}
                      open={todateOpen}
                      slotProps={{
                        textField: { onClick: () => setTodateOpen(true) },
                      }}
                      onClose={() => setTodateOpen(false)}
                      onChange={(newDate, event) => {
                        // console.log("newDate", newDate);
                        setToDate(newDate);
                        formik.setFieldValue("toDate", newDate);
                      }}
                      disableFuture
                      format="dd/MM/yyy"
                      minDate={formik.values.fromDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    {formik.touched.toDate && formik.errors.toDate ? (
                      <Typography color="error">
                        {formik.errors.toDate}
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Div>

                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">Status</Typography>

                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="status"
                      value={formik.values.status}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      displayEmpty
                      MenuProps={{
                        classes: { paper: "custom-menu-paper-select" }, // Assign a class to the dropdown menu
                      }}
                      renderValue={
                        formik.values.status !== ""
                          ? undefined
                          : () => "Select Status"
                      }
                    >
                      {status?.map((item) => {
                        return (
                          <MenuItem value={item?.id}>
                            {item?.statusName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formik.touched.status && formik.errors.status ? (
                      <Typography color="error">
                        {formik.errors.status}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Div>

                <Div sx={{ mt: 3 }}>
                  <Typography className="from_label">Country</Typography>

                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="country"
                      value={formik.values.country}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      displayEmpty
                      MenuProps={{
                        classes: { paper: "custom-menu-paper-select" },
                      }}
                      renderValue={
                        formik.values.country !== ""
                          ? undefined
                          : () => "Select Country"
                      }
                    >
                      {country?.map((item) => {
                        return (
                          <MenuItem value={item?.countryId}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Div>

                <Div
                  sx={{
                    mt: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LoadingButton
                    loading={loading}
                    className="filter_bttn"
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ marginRight: "10px" }}
                  >
                    Filter
                  </LoadingButton>

                  <Button
                    className="clear_btn"
                    onClick={handleClear}
                    variant="contained"
                    fullWidth
                    style={{ marginLeft: "10px" }}
                  >
                    Clear
                  </Button>
                </Div>

                <Div></Div>
              </form>
            </div>
          </Box>
        </Drawer>
      </div>

      <UpdateStatus
        id={updateId}
        handleDrawerClose={handleDrawerClose}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        loadData={loadData}
      />
    </Fragment>
  );
};

export default ManageEnquiry;
