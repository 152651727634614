
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import axios from "axios";
import moment from "moment";

import { Box, Button, Card, Grid, Typography } from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import DownloadIcon from "@mui/icons-material/Download";

import { ToastServices } from "app/services/toastServices";
import { config } from "app/config/main";
import { Encrytion } from "app/utils/EncryptionHelpers";
import LoaderComp from "app/components/loader/Loader";
import { DOC } from "app/assets";

const ViewApplication = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pageloader, setPageLoader] = useState(false);
  const [viewdata, setViewDate] = useState({});
  const getToken = sessionStorage.getItem("token");
  const EncryptToken = Encrytion(getToken);

  // get job view

  const getView = async () => {
    setPageLoader(true);
    const headers = {
      Authorization: `Bearer ${EncryptToken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(
        `${config.apiUrl}/jobs/appliedjob/${id}`,
        {
          headers,
        }
      );

      if (!response.status === true) {
        ToastServices.ErroToast("Something went wrong");
        setPageLoader(false);
      }

      setViewDate(response?.data?.data);
      setPageLoader(false);
    } catch (e) {}
  };
  const handleDownload = async (file) => {
    const segments = file.split("/");
    const filename = segments[segments.length - 1];

    const headers = {
      Authorization: `Bearer ${EncryptToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.get(
        `${config.apiUrl}/upload/download/${filename}`,
        {
          headers,
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      ToastServices.SucessToast("File downloaded successfully");
    } catch (error) {
      ToastServices.ErroToast("Something went wrong ");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getView();
      } catch (error) {
      }
    };
    fetchData();
    return () => {};
  }, []);

  const handleBacktoList = () => {
    navigate("/job-application");
  };
  return (
    <div>
      <Box>
        <Div className="div_container d-flex justify-content space-between">
          <div className="d-flex justify-content-start flex-start">
            <div className="mt-10">
              <Typography
                className="heading"
                variant={"h2"}
                mb={5}
                fontWeight={600}
              >{`View Application`}</Typography>
            </div>
          </div>

          <div className="d-flex justify-content-end flex-end mr-20">
            <div className="">
              <Button
                variant="outlined"
                className="outlined_btn"
                onClick={handleBacktoList}
              >
                <ChevronLeftOutlinedIcon sx={{ mb: 0.2, mr: 0.5 }} />
                Back
              </Button>
            </div>
          </div>
        </Div>

        <Grid container xs={12} spacing={2}>
          <Grid item xs={6} spacing={2}>
            <Card sx={{ minHeight: "500px" }}>
              <Typography
                variant={"h4"}
                m={3}
                fontWeight={600}
              >{`Application Info`}</Typography>
              <div className="beneficarty_add_details" sx={{ mb: 1 }}>
                <table className="w-100 beneficary_details">
                  <tr>
                    <td className="table_label">Date</td>
                    <td className="table_value">
                      {moment(viewdata?.createdAt).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td className="table_label">Job Title</td>
                    <td className="table_value">
                      {viewdata[0]?.job?.jobTitle}
                    </td>
                  </tr>
                  <tr>
                    <td className="table_label">Name</td>
                    <td className="table_value">{viewdata[0]?.fullName}</td>
                  </tr>
                  <tr>
                    <td className="table_label">Email ID</td>
                    <td className="table_value">{viewdata[0]?.email}</td>
                  </tr>
                  <tr>
                    <td className="table_label">Mobile</td>
                    <td className="table_value">{viewdata[0]?.mobileNo}</td>
                  </tr>

                  <tr>
                    <td className="table_label">Status</td>
                    <td className="table_value">
                      <span
                        className={
                          viewdata[0]?.status === 1
                            ? "status new"
                            : viewdata[0]?.status === 2
                            ? "status under_review"
                            : viewdata[0]?.status === 3
                            ? "status interviewed"
                            : viewdata[0]?.status === 4
                            ? "status offered"
                            : viewdata[0]?.status === 5
                            ? "status rejected"
                            : ""
                        }
                      >
                        {viewdata[0]?.status === 1
                          ? "New"
                          : viewdata[0]?.status === 2
                          ? "Under Review"
                          : viewdata[0]?.status === 3
                          ? "Interviewed"
                          : viewdata[0]?.status === 4
                          ? " Offered"
                          : viewdata[0]?.status === 5
                          ? " Rejected"
                          : ""}
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </Card>
          </Grid>

          <Grid item xs={6}>
            <Card style={{ position: "relative" }} sx={{ minHeight: "500px" }}>
              <Typography
                variant={"h4"}
                style={{ position: "relative", zIndex: 9 }}
                m={3}
                fontWeight={600}
              >{`Cover Letter Info`}</Typography>
              <Div
                sx={{ ml: 3, mr: 3, marginTop: "2rem", marginBottom: "2rem" }}
              >
                <h3>Cover Letter</h3>
                <p style={{textAlign:"justify"}}>{viewdata[0]?.coverLetter}</p>

                <div className="view-upload">
                  <h3>Upload CV</h3>
                  <a onClick={() => handleDownload(viewdata[0]?.resume)} target="_blank">
                    <img src={DOC} alt="doc" />
                  </a>

                  <div className="mt-15">
                    <Button
                      variant="outlined"
                      className="filled_btn mb-0"
                      sx={{ fontSize: "10px" }}
                      onClick={() => handleDownload(viewdata[0]?.resume)}
                    >
                      <DownloadIcon
                        className="mr-5"
                        sx={{ fontSize: "20px" }}
                      />
                      Download
                    </Button>
                  </div>
                </div>
              </Div>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {pageloader === true ? <LoaderComp /> : null}
    </div>
  );
};

export default ViewApplication;
