import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import Div from "@jumbo/shared/Div";
import { Field, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import moment from "moment";
import MultiSelect from "multiselect-react-dropdown";
import LoaderComp from "app/components/loader/Loader";
import { ToastServices } from "app/services/toastServices";
import { config } from "app/config/main";
import { Encrytion } from "app/utils/EncryptionHelpers";
import ReactQuill from "react-quill";
import MultiValueInput from "./MultiValueComponent";
import 'react-quill/dist/quill.snow.css';


const UpdateJob = () => {
  const {id} = useParams()
  const [category, setCategory] = useState([]);
  const [skill, setSkill] = useState([]);
  const [career, setCareer] = useState([]);
  const [jobTime, setJobTime] = useState([]);
  const [degree, setDegree] = useState([]);
  const [todateOpen, setTodateOpen] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewdata, setViewDate] = useState({})
  const [getCatename, setCateName] = useState({})
  const [getSkills, setSkills] = useState([])
  const [getdegree, setGetDegree] = useState({})
  const [pageloader, setPageLoader]= useState(true)
  
  const navigate = useNavigate();
  
  // getView call 

  const getView = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(`${config.apiUrl}/jobs/${id}`,{ headers });

      if (!response.status === true) {
        ToastServices.ErroToast("Something went wrong");
        setPageLoader(false)
      }
      setViewDate(response?.data?.data);
      setCateName(response?.data?.data?.category)
      setSkills(response?.data?.data?.skills)
      setGetDegree(response?.data?.data?.DegreeLevel)
      await loadState(response?.data?.data?.country?.countryId)
       await loadCity(response?.data?.data?.state?.stateId)
       setPageLoader(false)
    } catch (e) {
    }
  };

  // console.log(getdegree)

  const regexString = "^\\S$|^\\S[\\s\\S]*\\S$";
  const validationSchema = yup.object({
    jobTitle: yup.string().matches(new RegExp(regexString), "Please provide valid job title").required("Job title is required"),
    jobCategory: yup
      .string()
      .notOneOf(["120000"], "Job category is required")
      .required("Job category is required"),
    jobSkill: yup
      .array()
      .min(1, "Select at least one job skill")
      .required("Job skill is required"),
    gender: yup
      .string()
      .notOneOf(["120000"], "Gender is required")
      .required("Gender is required"),
    fromSalary: yup
      .number()
      .required("From salary is required")
      .min(0.1, "From salary must be greater than 0"),
    toSalary: yup
      .number()
      .required("To salary is required")
      .min(0.1, "To salary must be greater than 0")
      .when("fromSalary", (fromSalary, schema) =>
        fromSalary
          ? schema.min(fromSalary, "To salary cannot be lower than From salary")
          : schema
      ),
    jobExpiryDate: yup
      .date()
      .required("Job expiry date is required")
      .nullable(),
    country: yup
      .string()
      .notOneOf(["120000"], "Country is required")
      .required("Country is required"),
    state: yup
      .string()
      .notOneOf(["120000"], "State is required")
      .required("State is required"),
    city: yup
      .string()
      .notOneOf(["120000"], "City is required")
      .required("City is required"),
    careerLevel: yup
      .string()
      .notOneOf(["120000"], "career level is required")
      .required("Career level is required"),
    jobShift: yup
      .string()
      .notOneOf(["120000"], "Job shift is required")
      .required("job shift is required"),
    jobTag: yup
      .array()
      .of(yup.string().required(""))
      .min(1, "Job tag is required"),
    DegreeLevel: yup
      .string()
      .notOneOf(["120000"], "Degree level is required")
      .required("Degree level is required"),
    positions: yup.number().required("Number of positions is required").min(0.1, "Number of positions must be greater than 0"),
    experience: yup.number().required("Experience is required").min(0, "Experience must be greater than or equal to 0"),
    keyResponsibilities: yup
      .string().test(
        'is-not-empty',
        'Key responsibilities is required ',
        value => value && value.replace(/<[^>]*>?/gm, '').trim().length > 0
      )
      .test(
        'min-length',
        'Key responsibilities must be at least 50 characters',
        value => value && value.replace(/<[^>]*>?/gm, '').trim().length >= 50
      )
      .required('Description is required'),
    Description: yup.string().test(
      'is-not-empty',
      'Description is required',
      value => value && value.replace(/<[^>]*>?/gm, '').trim().length > 0
    )
    .test(
      'min-length',
      'Description must be at least 50 characters',
      value => value && value.replace(/<[^>]*>?/gm, '').trim().length >= 50
    )
    .required('Description is required')
  });
  const initialValues = {
    jobTitle: viewdata?.jobTitle,
    jobCategory: viewdata?.category?.id ,
    jobSkill: viewdata?.skills?.map(skill => skill.id) || [],
    gender: viewdata?.gender,
    fromSalary: viewdata?.salaryFrom,
    toSalary:  viewdata?.salaryTo,
    jobExpiryDate: viewdata ?  moment(moment(viewdata?.jobExpiryDate).format("DD/MM/YYYY"), "DD/MM/YYYY").toDate() : null,
    country:  viewdata?.country?.countryId || "",
    state: viewdata?.state?.stateId || "",
    city:  viewdata?.district?.districtId || "",
    careerLevel: viewdata?.carrier?.id,
    jobShift:  viewdata?.job_shift?.id,
    jobTag: viewdata?.jobTag,
    DegreeLevel: viewdata?.DegreeLevel?.id,
    positions: viewdata?.noOfPositions    ,
    experience: viewdata?.experience,
    keyResponsibilities: viewdata?.keyResponsibility,
    Description: viewdata?.description,
    hideSalary: viewdata?.hideSalary,
    freeLance: viewdata?.isFreelance,
  };

  const gender = [
    { value: "MALE", genderName: "Male" },
    { value: "FEMALE", genderName: "Female" },
    { value: "BOTH", genderName: "Both" },
  
  ];

  const EncryptTOken = Encrytion(sessionStorage.getItem("token"));

  //   get depency api

  const getCategories = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(
        `${config.apiUrl}/job-category/raw_categories?status=0`,
        { headers }
      );

      setCategory(response?.data?.data);
    } catch (e) {
    }
  };

  // get Skill

  const getSkill = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const getresponse = await axios.get(
        `${config.apiUrl}/job-skills/raw_skills?status=0`,
        { headers }
      );
      setSkill(getresponse?.data?.data);
    } catch (e) {}
  };

  const getCareer = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(`${config.apiUrl}/career-level/list`, {
        headers,
      });
      setCareer(response?.data?.data);
    } catch (e) {
    }
  };

  const getJobLevel = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(`${config.apiUrl}/job-shift/list`, {
        headers,
      });

      setJobTime(response?.data?.data);
    } catch (e) {
    }
  };

  const getDegree = async () => {
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(
        `${config.apiUrl}/degree-level/raw_degree?status=0`,
        { headers }
      );
      setDegree(response?.data?.data);
    } catch (e) {
    }
  };

  const fetchCountries = async () => {
    try {
      const getresponseCountry = await axios.get(`${config.apiUrl}/country`);

      setCountries(getresponseCountry?.data?.data);
    } catch (e) {
    }
  };

  const loadState = async (stateId) => {
    try {
      const getresponseState = await axios.get(
        `${config.apiUrl}/state/${stateId}`
      );
      setStates(getresponseState?.data?.data);
    } catch (e) {
    }
  };

  const loadCity = async (cityId) => {
    try {
      const getresponseCity = await axios.get(
        `${config.apiUrl}/district/${cityId}`
      );
      setCities(getresponseCity?.data?.data);
    } catch (e) {
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCategories();
        await getSkill();
        await fetchCountries();
        await getCareer();
        await getJobLevel();
        await getDegree();
        await getView()
       
      } catch (error) {
      }
    };

    fetchData();

    return () => {};
  }, []);

  const postJobData = async (values) => {
    setLoading(true)
    const userData = {
      jobTitle: values?.jobTitle,
      gender: values?.gender,
      salaryFrom: values?.fromSalary,
      salaryTo: values?.toSalary,
      jobExpiryDate:moment(values?.jobExpiryDate).toISOString(),
      noOfPositions: values?.positions,
      description: values?.Description,
      keyResponsibility: values?.keyResponsibilities,
      experience: values?.experience,
      postingDate: moment().format("DD/MM/YYYY"),
      hideSalary: values?.hideSalary,
      isFreelance: values?.freeLance,
      jobTag: values?.jobTag,
      categoryId: values?.jobCategory,
      skillsId: values?.jobSkill,
      countryId: values?.country,
      stateId: values?.state,
      districtId: values?.city,
      carrierLevelId: values?.careerLevel,
      jobShiftId: values?.jobShift,
      degreeLevelId: values?.DegreeLevel,
    };

    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };
    try {
      const postResponse = await axios.put(`${config.apiUrl}/jobs/update/${id}`, userData, {headers});
      if (postResponse?.data?.status === true) {
        setLoading(false);
        ToastServices.SucessToast(postResponse?.data?.message);
        navigate('/manage-job')
      } else if (postResponse?.data?.status === false) {
        ToastServices.ErroToast(postResponse?.data?.message);
        setLoading(false);
      } else if (postResponse?.data?.statusCode === 429) {
        setLoading(false);
        ToastServices.ErroToast("Something went wrong");
      } else if (postResponse?.data?.statusCode === 500) {
        ToastServices.ErroToast("Something went wrong");
        setLoading(false);
      } else if (postResponse?.data?.statusCode === 404) {
        ToastServices.ErroToast("Something went wrong");
        setLoading(false);
      }
    } catch (e) {}
  };

  const handlekeydown = (evt) =>{
    ["e", "E", "+", "-", "ctrlKey", "vKey"].includes(evt.key) &&evt.preventDefault();
    if (evt.keyCode === 38 || evt.keyCode === 40 || evt.keyCode === 17 || evt.keyCode === 86) {
      evt.preventDefault();
    }
  }

  const handlekeydownPos = (evt) =>{
    ["e", "E", "+", "-", ".",  "ctrlKey", "vKey"].includes(evt.key) &&evt.preventDefault();
    if (evt.keyCode === 38 || evt.keyCode === 40 || evt.keyCode === 17 || evt.keyCode === 86) {
      evt.preventDefault();
    }
  }






  return (
    <div>
      <Div className="div_container d-flex justify-content space-between">
        <div className="d-flex justify-content-start flex-start">
          <div className="mt-10">
            <Typography
              className="heading"
              variant={"h2"}
              mb={5}
              fontWeight={600}
            >{`Update Job`}</Typography>
          </div>
        </div>

        <div className="d-flex justify-content-end flex-end mr-20">
          <div className="">
            <Link to={'/manage-job'}>
          
            <Button variant="outlined" className="outlined_btn">
              <ChevronLeftOutlinedIcon
                sx={{ mb: 0.2, mr: 0.5 }} />
              Back
            </Button>
            </Link>
          </div>
        </div>
      </Div>
     
      <Card sx={{ padding: "12px" }}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            enableReinitialize={true}
            //   onSubmit={handleSubmit}
            onSubmit={(values) => {
              postJobData(values);
            }}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              handleBlur,
              errors,
              touched,
              setFieldTouched,
            }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job title <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="text"
                      value={values.jobTitle}
                      name="jobTitle"
                      onChange={(e) =>handleChange(e, setFieldValue)} 
                      onBlur={handleBlur}
                      error={touched.jobTitle && Boolean(errors.jobTitle)}
                      helperText={touched.jobTitle && errors.jobTitle}
                      placeholder="Enter Job Title"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Category <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.jobCategory && Boolean(errors.jobCategory)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="jobCategory"
                        value={values.jobCategory}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.jobCategory !== ""
                            ? () => {
                                const categoryop = getCatename?.id === values?.jobCategory;
                                //  console.log(categoryop)
                                return categoryop ? getCatename?.categoryName : category.find(cat => cat.id === values.jobCategory)?.categoryName || "Select Job Category"   ;
                              }
                            : () => "Select Job Category"
                        }
                        
                      >
                        {category?.map((item) => {
                          return (
                            <MenuItem value={item?.id}>
                              {item?.categoryName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {touched.jobCategory && errors.jobCategory && (
                        <FormHelperText>{errors.jobCategory}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Skill <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.jobSkill && Boolean(errors.jobSkill)}
                    >
                      <MultiSelect
                        showCheckbox={true}
                        className={
                          touched.jobSkill && errors.jobSkill && "border_red"
                        }
                        options={skill}
                        selectedValues={
                         

                          getSkills?.filter((skill) =>
                           values.jobSkill.includes(skill.id)
                        )
                      }
                        onSelect={(selectedList) => {
                          setFieldValue(
                            "jobSkill",
                            selectedList.map((item) => item.id)
                          );
                          setFieldTouched("jobSkill", false);
                        }}
                        onRemove={(selectedList) => {
                          setFieldValue(
                            "jobSkill",
                            selectedList.map((item) => item.id)
                          );
                          setFieldTouched("jobSkill", false);
                        }}
                        displayValue="skillName"
                        placeholder="Select Job Skills"
                      />
                      {touched.jobSkill && errors.jobSkill && (
                        <FormHelperText>{errors.jobSkill}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Gender <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.gender && Boolean(errors.gender)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="gender"
                        value={values.gender}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          () =>
                          values?.gender !== ""
                            ? values?.gender?.charAt(0) + values?.gender?.slice(1)?.toLowerCase()
                            : "Select Gender"
                        }
                        
                      >
                        {gender?.map((item) => {
                          return (
                            <MenuItem value={item?.value}>
                              {item?.genderName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.gender && errors.gender && (
                        <FormHelperText>{errors.gender}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Salary From (CTC in Lakhs){" "}
                        <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      onKeyDown={(evt) =>handlekeydown(evt)}
                      value={values.fromSalary}
                      name="fromSalary"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.fromSalary && Boolean(errors.fromSalary)}
                      helperText={touched.fromSalary && errors.fromSalary}
                      placeholder="Enter Salary From CTC (in Lakhs)"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Salary To (CTC in Lakhs){" "}
                        <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      onKeyDown={(evt) =>handlekeydown(evt)}
                      value={values.toSalary}
                      name="toSalary"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.toSalary && Boolean(errors.toSalary)}
                      helperText={touched.toSalary && errors.toSalary}
                      placeholder="Enter Salary To (CTC in Lakhs)"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Expiry Date <span className="required">*</span>
                      </span>
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className= { touched.jobExpiryDate && errors.jobExpiryDate ? "form_new_style date_error"  : 'form_new_style'  } 
                        name="jobExpiryDate"
                        value={values.jobExpiryDate}
                        open={todateOpen}
                        onBlur={handleBlur}
                        slotProps={{
                          textField: { onClick: () => setTodateOpen(true) },
                        }}
                        onClose={() => setTodateOpen(false)}
                        onChange={(newDate, event) => {
                          setToDate(newDate);
                          setFieldValue("jobExpiryDate", newDate);
                        }}
                        disablePast
                        format="dd/MM/yyy"
                        renderInput={(params) => (
                          <TextField onBlur={handleBlur} {...params} />
                        )}
                      />
                      {touched.jobExpiryDate && errors.jobExpiryDate ? (
                        <Typography color="error">
                          <FormHelperText>
                            {errors.jobExpiryDate}
                          </FormHelperText>
                        </Typography>
                      ) : null}
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        {" "}
                        Country <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.country && Boolean(errors.country)}
                    >
                      <Select
                        labelId="country"
                        id="country"
                        name="country"
                        value={values.country}
                        onChange={async (event) => {
                          const selectedCountryId = event.target.value;
                          setFieldValue("country", selectedCountryId);
                          setFieldTouched('country', true);
                          setFieldValue("state", "");
                          setFieldValue("city", "");
                          if (selectedCountryId) {
                            loadState(selectedCountryId);
                          }
                        }}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                         values.country !== ""
                           ? () => {
                              const categoryop = countries?.find(cat => cat.countryId === values.country)
                               return categoryop ? categoryop?.name : "Select Country" 
                             }
                            : () => "Select Country"
                        }>
                        {countries?.map((item) => {
                          return (
                            <MenuItem key={item.countryId} value={item?.countryId}>
                              {item?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.country && errors.country && (
                        <FormHelperText>{errors.country}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        {" "}
                        State <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.state && Boolean(errors.state)}
                    >
                      <Select
                        className={
                          touched.state && errors.state && "custom_error"
                        }
                        labelId="state"
                        id="state"
                        name="state"
                        value={values.state}
                        onChange={async (event) => {
                          const selectedStateID = event.target.value;
                          setFieldValue("state", selectedStateID);
                          setFieldTouched('state', true);
                          setFieldValue("city", "");
                          if (selectedStateID) {
                            loadCity(selectedStateID);
                          }
                        }}
                        disabled={!values.country}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.state !== ""
                            ? () => {
                                const categoryop = states?.find(cat => cat.stateId === values.state);
                                return categoryop ? categoryop?.stateName : "Select State";
                              }
                            : () => "Select State"
                        }
                        >
                        {states?.map((item) => {
                          return (
                            <MenuItem key={item.stateId} value={item?.stateId}>
                              {item?.stateName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.state && errors.state && (
                        <FormHelperText className="required">
                          {errors.state}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        {" "}
                        City <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.city && Boolean(errors.city)}
                    >
                      <Select
                        className={
                          touched.city && errors.city && "custom_error"
                        }
                        labelId="city"
                        id="city"
                        name="city"
                        value={values.city}
                        onChange={async (event) => {
                          const cityID = event.target.value;
                          setFieldValue("city", cityID);
                          setFieldTouched('city', false);
                          touched.city = false;
                          errors.city = false;
                        }}
                        disabled={!values.country || !values.state}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                       
                        renderValue={
                          values.city !== ""
                            ? () => {
                                const categoryop = cities?.find(cat => cat.districtId === values.city);
                                return categoryop ? categoryop?.districtName : "Select city";
                              }
                            : () => "Select City"
                        }

                      >
                        {cities?.map((item) => {
                          return (
                            <MenuItem key={item.districtId} value={item?.districtId}>
                              {item?.districtName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.city && errors.city && (
                        <FormHelperText className="required">
                          {errors.city}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Career Level <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.careerLevel && Boolean(errors.careerLevel)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="careerLevel"
                        value={values.careerLevel}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                         }}
                      renderValue={
                       values.careerLevel !== ""
                            ? () => {
                                const categoryop = career?.find(cat => cat.id === values.careerLevel);
                                return categoryop ? categoryop?.careerName : "Select Career Level";
                              }
                            : () => "Select Career Level"
                        }
                       >
                        {career?.map((item) => {
                          return (
                            <MenuItem value={item?.id}>
                              {item?.careerName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                     {touched.careerLevel && errors.careerLevel && (
                        <FormHelperText>{errors.careerLevel}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Shift <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.jobShift && Boolean(errors.jobShift)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="jobShift"
                        value={values.jobShift}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={ 
                          values.jobShift !== ""
                            ? () => {
                                const categoryop = jobTime?.find(cat => cat.id === values.jobShift);
                                return categoryop ? categoryop?.shiftName : "Select Job Shift";
                              }
                            : () => "Select Job Shift"
                        }
                       >
                        {jobTime?.map((item) => {
                          return (
                            <MenuItem value={item?.id}>
                              {item?.shiftName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.jobShift && errors.jobShift && (
                        <FormHelperText>{errors.jobShift}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Tag <span className="required">*</span>
                      </span>
                    </Typography>
                    <FieldArray name="jobTag">
                      {({ push, remove }) => (
                        <>
                          <MultiValueInput
                            values={values.jobTag}
                            onAdd={(value) => push(value)}
                            onDelete={(index) => remove(index)}
                            error={errors.jobTag}
                            touched={touched.jobTag}
                          />
                          {touched.jobTag && errors.jobTag && (
                            <FormHelperText className="required">
                              {errors.jobTag}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    </FieldArray>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Degree Level <span className="required">*</span>
                      </span>
                    </Typography>
                    <FormControl
                      fullWidth
                      error={touched.DegreeLevel && Boolean(errors.DegreeLevel)}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="DegreeLevel"
                        value={values.DegreeLevel}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          classes: { paper: "" },
                        }}
                        renderValue={
                          values.DegreeLevel !== ""
                            ? () => {
                                const categoryop = getdegree?.id === values?.DegreeLevel;
                                //  console.log(categoryop)
                                return categoryop ? getdegree?.degreeName : degree.find(cat => cat.id === values.DegreeLevel)?.degreeName || "Select Degree Level"   ;
                              }
                            : () => "Select Degree Level"
                        }
                      >
                        {degree?.map((item) => {
                          return (
                            <MenuItem value={item?.id}>
                              {item?.degreeName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      {touched.DegreeLevel && errors.DegreeLevel && (
                        <FormHelperText>{errors.DegreeLevel}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                      No. of Positions <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      onKeyDown={(evt) =>handlekeydownPos(evt)}
                      value={values.positions}
                      name="positions"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.positions && Boolean(errors.positions)}
                      helperText={touched.positions && errors.positions}
                      placeholder="Enter No. of Positions"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Job Experience (In Years) <span className="required">*</span>
                      </span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      onKeyDown={(evt) =>handlekeydown(evt)}
                      value={values.experience}
                      name="experience"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.experience && Boolean(errors.experience)}
                      helperText={touched.experience && errors.experience}
                      placeholder="Enter Job Experience"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Key Responsibilities <span className="required">*</span>
                      </span>
                    </Typography>

                    <Field name="keyResponsibilities">
                      {({ field }) => (
                        <ReactQuill
                          theme="snow"
                          value={field.value}
                          onChange={(html) => {
                            setFieldValue(field.name, html);
                          }}
                          onBlur={() => {
                            setFieldTouched(field.name, true);
                          }}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: ['Inter'] }],
                              [{ size: ['small', false, 'large', 'huge'] }],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],
                              [
                                { list: "ordered" },
                                { list: "bullet" },
                                { indent: "-1" },
                                { indent: "+1" },
                              ],
                              ["link", ],
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "link",
                           
                          ]}
                          style={{ height: "230px" }}
                        />
                      )}
                    </Field>

                    {touched.keyResponsibilities &&
                      errors.keyResponsibilities && (
                        <FormHelperText>
                          {errors.keyResponsibilities}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>
                        Description <span className="required">*</span>
                      </span>
                    </Typography>

                    <Field name="Description">
                      {({ field }) => (
                        <ReactQuill
                          theme="snow"
                          value={field.value}
                          onChange={(html) => {
                            setFieldValue(field.name, html);
                          }}
                          onBlur={() => {
                            setFieldTouched(field.name, true);
                          }}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: ['Inter'] }],
                              [{ size: ['small', false, 'large', 'huge'] }],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],
                              [
                                { list: "ordered" },
                                { list: "bullet" },
                                { indent: "-1" },
                                { indent: "+1" },
                              ],
                              ["link", ],
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "link",
                          
                          ]}
                          style={{ height: "230px" }}
                        />
                      )}
                    </Field>

                    {touched.Description && errors.Description && (
                      <FormHelperText>{errors.Description}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>Hide Salary</span>
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.hideSalary}
                          onChange={handleChange}
                          name="hideSalary"
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <Typography className="login_label d-flex items-center g-10">
                      <span>Freelance</span>
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.freeLance}
                          onChange={handleChange}
                          name="freeLance"
                        />
                      }
                    />
                  </Grid>

                  <Grid item md={12}>
                    <LoadingButton
                      loading={loading}
                      className="primary_btn w-365"
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      
        {pageloader === true  ?   <LoaderComp /> : null}
        
    
    </div>
  );
};

export default UpdateJob;

