import React from "react";
import Page from "@jumbo/shared/Page";
/* import Home from "../pages/home";
import PermissionList from "../pages/app-pages/permissionList";
import ManageRoles from "../pages/app-pages/manageRoles/ManageRoles";
import ManageUsers from "../pages/app-pages/manageUsers/ManageUsers";
import RolesList from "app/pages/app-pages/rolesList/RolesList";
import ChangePassword from "app/pages/app-pages/changePassword/ChangePassword";
import ActivityLog from "app/pages/app-pages/activityLog/ActivityLog";
import NotificationList from "app/pages/app-pages/notificationList/NotificationList";
import CreateRole from "app/pages/app-pages/manageRoles/CreateRole/CreateRole";
import UpdateRole from "app/pages/app-pages/manageRoles/UpdateRole/UpdateRole";
import CreateUser from "app/pages/app-pages/manageUsers/CreateUser/CreateUser";
import UpdateUser from "app/pages/app-pages/manageUsers/UpdateUser/UpdateUser";
import BeneficiariesList from "app/pages/app-pages/Beneficiaries/BeneficiarieList/BeneficiariesList"; */
import ManageEnquiry from "app/pages/app-pages/manageEnquiry/ManageEnquiry";
import View from "app/pages/app-pages/manageEnquiry/EnquiryView";
import ChangePassword from "app/pages/app-pages/changePassword/ChangePassword";
import ManageJobs from "app/pages/app-pages/manage-jobs/ManageJobs";
import CreateJob from "app/pages/app-pages/manage-jobs/CreateJobs";
import UpdateJob from "app/pages/app-pages/manage-jobs/UpdateJobs";
import AppliedJobList from "app/pages/app-pages/appliedJobs/AppliedJobList";
import ManageEventsList from "app/pages/app-pages/manage-events/ManageEventsList";
import CreateEvents from "app/pages/app-pages/manage-events/CreateEvents";
import CreateJobCategory from "app/pages/app-pages/jobCategory/CreateJobCategory";
import ManageJobsCategory from "app/pages/app-pages/jobCategory/jobCategoryList";
import UpdateJobCategory from "app/pages/app-pages/jobCategory/updateJobCategory";
import ManageJobSkill from "app/pages/app-pages/jobSkill/jobSkillList";
import CreateJobSkill from "app/pages/app-pages/jobSkill/CreateJobSkill";
import UpdateJobSkill from "app/pages/app-pages/jobSkill/updateJobSkill";
import ManageDegreeLevel from "app/pages/app-pages/degreeLevel/DegreeLevelList";
import CreateDegreeLevel from "app/pages/app-pages/degreeLevel/CreateDegreeLevel";
import UpdateDegreeLevel from "app/pages/app-pages/degreeLevel/updateDegreeLevel";
import ViewApplication from "app/pages/app-pages/appliedJobs/ViewApplication";
import ViewJobs from "app/pages/app-pages/manage-jobs/ViewJobs";
import ManageGallary from "app/pages/app-pages/manage-events/ManageGallary";
import UpdateEvents from "app/pages/app-pages/manage-events/UpdateEvents";
import ViewEvents from "app/pages/app-pages/manage-events/ViewEvents";

const appRoutes = [
  /*  {
        path: "/home",
        element: <Page component={Home} layout={"admin-layout"} /> 
    },
    {
        path: 'settings/roles-permissions',
        element: <Page component={RolesList} layout={"admin-layout"} /> 
    }, 
    {  
        path: 'settings/roles-permissions/permission-list/:id', 
        element: <Page component={PermissionList} layout={"admin-layout"}/> 
    },  
    {
        path: 'settings/manage-roles',
        element: <Page component={ManageRoles} layout={"admin-layout"} /> 
    }, 
    {
        path: 'settings/manage-roles/create',
        element: <Page component={CreateRole} layout={"admin-layout"} />
    },  
    {
        path: 'settings/manage-roles/update/:id',
        element: <Page component={UpdateRole} layout={"admin-layout"} />
    },  
    {
        path: 'settings/manage-users',
        element: <Page component={ManageUsers} layout={"admin-layout"} />
    },
    {
        path: 'settings/manage-users/create',
        element: <Page component={CreateUser} layout={"admin-layout"} />
    }, 
    {
        path: 'settings/manage-users/update/:id',
        element: <Page component={UpdateUser} layout={"admin-layout"} />
    },
   
    {    
        path: "settings/activity-log",
        element: <Page component={ActivityLog} layout={"admin-layout"} />
    },          
    {               
        path: "notification-details",
        element: <Page component={NotificationList} layout={"admin-layout"} />
    },   */
  {
    path: "/change-password",
    element: <Page component={ChangePassword} layout={"admin-layout"} />,
  },
  /* {
    path: "change-profile",
    element: <Page component={EditProfile} layout={"admin-layout"} />,
  }, */
  {
    path: "manage-enquiry",
    element: <Page component={ManageEnquiry} layout={"admin-layout"} />,
  },
  {
    path: "manage-enquiry/view/:id",
    element: <Page component={View} layout={"admin-layout"} />,
  },
  {
    path: "manage-job",
    element: <Page component={ManageJobs} layout={"admin-layout"} />,
  },
  {
    path: "manage-job/create",
    element: <Page component={CreateJob} layout={"admin-layout"} />,
  },
  {
    path: "manage-job/update/:id",
    element: <Page component={UpdateJob} layout={"admin-layout"} />,
  },
  {
    path: "manage-job/view/:id",
    element: <Page component={ViewJobs} layout={"admin-layout"} />,
  },
  {
    path: "/manage-events",
    element: <Page component={ManageEventsList} layout={"admin-layout"} />,
  },
  {
    path: "/manage-events/create",
    element: <Page component={CreateEvents} layout={"admin-layout"} />,
  },
  {
    path: "/manage-events/update/:id",
    element: <Page component={UpdateEvents} layout={"admin-layout"} />,
  },
  {
    path: "/manage-events/view/:id",
    element: <Page component={ViewEvents} layout={"admin-layout"} />,
  },
  {
    path: "/manage-events/manage-gallary/:id",
    element: <Page component={ManageGallary} layout={"admin-layout"} />,
  },
  {
    path: "settings/manage-job-category",
    element: <Page component={ManageJobsCategory} layout={"admin-layout"} />,
  },
  {
    path: "settings/manage-job-category/create",
    element: <Page component={CreateJobCategory} layout={"admin-layout"} />,
  },
  {
    path: "settings/manage-job-category/update/:id",
    element: <Page component={UpdateJobCategory} layout={"admin-layout"} />,
  },
  

  {
    path: "settings/manage-job-skill",
    element: <Page component={ManageJobSkill} layout={"admin-layout"} />,
  },
  {
    path: "settings/manage-job-skill/create",
    element: <Page component={CreateJobSkill} layout={"admin-layout"} />,
  },
  {
    path: "settings/manage-job-skill/update/:id",
    element: <Page component={UpdateJobSkill} layout={"admin-layout"} />,
  },
  {
    path: "settings/manage-degree-level",
    element: <Page component={ManageDegreeLevel} layout={"admin-layout"} />,
  },
  {
    path: "settings/manage-degree-level/create",
    element: <Page component={CreateDegreeLevel} layout={"admin-layout"} />,
  },
  {
    path: "settings/manage-degree-level/update/:id",
    element: <Page component={UpdateDegreeLevel} layout={"admin-layout"} />,
  },
  {

    path: "job-application",
    element: <Page component={AppliedJobList} layout={"admin-layout"} />,
  },
  {
    path: "job-application/view/:id",
    element: <Page component={ViewApplication} layout={"admin-layout"} />,
  },
  /*  {
         path: "/test",
         element: <Page component={Test} layout={"admin-layout"} />,
        }, */
];

export default appRoutes;
