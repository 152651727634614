import { ClockSvg, JobBag, MapSvg } from "app/assets/svg";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Grid,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import Div from "@jumbo/shared/Div";
import axios from "axios";
import moment from "moment";
import { ToastServices } from "app/services/toastServices";
import { config } from "app/config/main";
import { Encrytion } from "app/utils/EncryptionHelpers";
import LoaderComp from "app/components/loader/Loader";
import SkeletonTableLoader from "./SkeletonLoader";
import NoRecordsFound from "./NoRecordsFound";

const ViewJobs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pageloader, setPageLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [jobData, setjobData] = useState({
    limit: "10",
    offset: "1",
    sortByType: "",
    sortByKey: "",
  });
  const [viewdata, setViewDate] = useState({});
  const EncryptTOken = Encrytion(sessionStorage.getItem("token"));

  // get job view

  const getView = async () => {
    setPageLoader(true);
    const headers = {
      Authorization: `Bearer ${EncryptTOken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(`${config.apiUrl}/jobs/${id}`, {
        headers,
      });

      if (!response.status === true) {
        ToastServices.ErroToast("Something went wrong");
        setPageLoader(false);
      }
      setViewDate(response?.data?.data);
      setPageLoader(false);
    } catch (e) {
    }
  };

  const findDays = (data) => {
  // Parse ISO 8601 date string
  const parsedDate = moment(data);

  if (!parsedDate.isValid()) {
    return "Invalid date"; // Handle invalid date format
  }

  // Format the date for display
  // const formattedDate = parsedDate.format("DD/MM/YYYY");

  // Calculate differences from current date and time
  const now = moment();
  const diffYears = now.diff(parsedDate, "years");
  const diffMonths = now.diff(parsedDate, "months");
  const diffDays = now.diff(parsedDate, "days");
  const diffHours = now.diff(parsedDate, "hours");
  const diffMin = now.diff(parsedDate, "minutes");

  // Determine relative time
  let relativeTime;

  if (diffYears === 1) {
    relativeTime = "1 year ago";
  } else if (diffYears > 1) {
    relativeTime = `${diffYears} years ago`;
  } else if (diffMonths === 1) {
    relativeTime = "1 month ago";
  } else if (diffMonths > 1) {
    relativeTime = `${diffMonths} months ago`;
  } else if (diffDays === 1) {
    relativeTime = "1 day ago";
  } else if (diffDays > 1) {
    relativeTime = `${diffDays} days ago`;
  } else if (diffHours === 1) {
    relativeTime = "1 hour ago";
  } else if (diffHours > 1) {
    relativeTime = `${diffHours} hours ago`;
  } else  if (diffMin > 1) {
    relativeTime = `${diffMin} minutes ago`;
  } else  {
    relativeTime = `Few seconds ago`;
  }
  return  relativeTime ;

   
  };

  // load applied jobs

  const loadData = async () => {
    const mergedData = { ...jobData };

    const { limit, offset, sortByType, sortByKey } = mergedData;

    let api = `${config.apiUrl}/jobs/appliedlist/job/${id}?&offset=${offset}`;

    if (limit) api += `&limit=${limit}`;
    if (sortByType) api += `&sortByType=${sortByType}`;
    if (sortByKey) api += `&sortByKey=${sortByKey}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${EncryptTOken}`,
      },
    };
    await fetch(api, options)
      .then((res) => res.json())
      .then((res) => {
        setData(res.data);
        if (res?.status === true) {
          setData(res.data);
          setCount(res?.status ? res.count : 0);
          setTotalPage(res?.status ? res.totalPages : 0);
        }
      });
  };



  const handlePaginationChange = (event, page) => {
    setPage(page);
    setjobData({ ...jobData, offset: page });
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setPage(1);
    setjobData({ ...jobData, limit: event.target.value, offset: 1 });
  };

  useEffect(() => {
    loadData();
  }, [jobData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getView();
      } catch (error) {
      }
    };

    fetchData();

    return () => {};
  }, []);

  const handleBacktoList = () => {
    navigate("/manage-job");
  };

  return (
    <div>
     
      <Box>
        <Div className="div_container d-flex justify-content space-between">
          <div className="d-flex justify-content-start flex-start">
            <div className="mt-10">
              <Typography
                className="heading"
                variant={"h2"}
                mb={5}
                fontWeight={600}
              >{`View Job`}</Typography>
            </div>
          </div>

          <div className="d-flex justify-content-end flex-end mr-20">
            <div className="">
              <Button
                variant="outlined"
                className="outlined_btn"
                onClick={handleBacktoList}
              >
                <ChevronLeftOutlinedIcon sx={{ mb: 0.2, mr: 0.5 }} />
                Back
              </Button>
            </div>
          </div>
        </Div>
        <Grid container xs={12} spacing={2}>
          <Grid item lg={9} spacing={2}>
            <Card>
              <div className="job-view-section" sx={{ ml: 3 }}>
                <span className="post_date">
                {moment(viewdata?.createdAt).format("MMMM DD, YYYY")}
                </span>
                <h2 className="job_title">{viewdata?.jobTitle}</h2>
                <div className="job_keys">
                  <div className="job_keys_cont">
                    <ClockSvg />
                    <p>{findDays(viewdata?.createdAt)}</p>
                  </div>
                  <div className="job_keys_cont">
                    <MapSvg />
                    <p>
                      {viewdata?.district?.districtName},{" "}
                      {viewdata?.state?.stateName}
                    </p>
                  </div>
                  <div className="job_keys_cont">
                    <JobBag />
                    <p>{viewdata?.job_shift?.shiftName}</p>
                  </div>
                </div>
                <div className="job_overview">
                  <p>
                    Job Title:<span>&nbsp;{viewdata?.jobTitle}</span>
                  </p>
                  <p>
                    Job Category:
                    <span>&nbsp;{viewdata?.category?.categoryName}</span>
                  </p>
                  <p>
                    No. of Positions:<span>&nbsp;{viewdata?.noOfPositions}</span>
                  </p>
                </div>
                <div className="job_view_description">
                  <h5>Job Description:</h5>
                  <div
                    dangerouslySetInnerHTML={{ __html: viewdata?.description }}
                  ></div>
                </div>
                <div className="job_view_description">
                  <h5>Responsibilities:</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: viewdata?.keyResponsibility,
                    }}
                  ></div>
                </div>
                <div className="job_view_description">
                  <h5>Skills Required:</h5>

                  <div className="chip-group">
                    {viewdata?.skills?.map((item, i) => (
                      <span className="chip_list" key={i}>
                        {item?.skillName}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
            <div>
              <h5 className="applicant-title">{`Applicants (${data?.length})`}</h5>
              <Card className="applicant-card">
                <div className="job_applicant">
                  <Div>
                    <Table className="common_table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Mobile Number</TableCell>

                          <TableCell>Email Id</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <>
                            <SkeletonTableLoader tablecell="7" row="14" />
                          </>
                        ) : data?.length === 0 ? (
                          <NoRecordsFound tablecell={5} />
                        ) : (
                          data?.map((item, i) => {
                            return (
                              <TableRow>
                                <TableCell>
                                  {moment(item?.createdAt).format("DD/MM/YYYY")}
                                </TableCell>

                                <TableCell>

                                  <Link to={`/job-application/view/${item?.id}`} style={{ textDecoration :"none" }}>
                                  <span className="link">
                                      {item?.fullName?.length > 30 ? (
                                        <>
                                          <Tooltip title={item?.fullName}>

                                          {item?.fullName?.substring(0, 30)}...
                                          </Tooltip>

                                        </>
                                      ) : (
                                        item?.fullName
                                      )}
                                    </span>
                                  </Link>
                                </TableCell>
                                <TableCell>{item?.mobileNo}</TableCell>
                                <TableCell>{item?.email}</TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <div className="count_page">
                        <span className="mr-10">Items per page :</span>
                        <select
                          className="pagination_selectbox"
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                        </select>
                      </div>
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPage}
                          page={page}
                          onChange={handlePaginationChange}
                        />
                      </Stack>
                    </div>
                  </Div>
                </div>
              </Card>
            </div>
          </Grid>

          <Grid item lg={3} spacing={2}>
            <Card>
              <div className="job-info-section">
                <h2 className="job_title ">Job Information</h2>
                <div className="job-info-overview">
                <div className="job-info-section-container">
                    <span>Gender</span>
                    <h5>{(viewdata?.gender)?.charAt(0) + viewdata?.gender?.slice(1)?.toLowerCase()}</h5>
                  </div>
                  <div className="job-info-section-container">
                    <span>Experience</span>
                    <h5>{viewdata?.experience === 0 ? "Fresher" : viewdata?.experience === 1 ? viewdata?.experience + "  " + "Year" :  viewdata?.experience + "  " + "Years"} </h5>
                  </div>
                  {
                     viewdata?.hideSalary !== true ? 
                     <div className="job-info-section-container">
                     <span>Salary</span>
                     <h5>
                       {viewdata?.salaryFrom} - {viewdata?.salaryTo} LPA
                     </h5>
                   </div> : null

                   }
                 
                  <div className="job-info-section-container">
                    <span>Posting Date</span>
                    <h5>{ moment(viewdata?.createdAt).format("DD/MM/YYYY")}</h5>
                  </div>
                  <div className="job-info-section-container">
                    <span>Expiring Date</span>
                    <h5>{moment(viewdata?.jobExpiryDate).format("DD/MM/YYYY")}</h5>
                  </div>
                  <div className="job-info-section-container">
                    <span>Career Level</span>
                    <h5>{viewdata?.carrier?.careerName}</h5>
                  </div>
                  <div className="job-info-section-container">
                    <span>Degree Level</span>
                    <h5>{viewdata?.DegreeLevel?.degreeName}</h5>
                  </div>
                  <div className="job-info-section-container">
                    <span>Is Freelance</span>
                    <h5>{viewdata?.isFreelance === true ? "Yes" : "No"}</h5>
                  </div>
                  <div className="job-info-section-container">
                    <span>Hide Salary</span>
                    <h5>{viewdata?.hideSalary === true ? "Yes" : "No"}</h5>
                  </div>
                </div>
                <div className="job-info-section-container">
                  <span>Job Tag</span>
                  <div className="chip-group">
                    {viewdata?.jobTag?.map((item, index) => (
                      <span className="chip_list">{item}</span>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {pageloader === true  ?   <LoaderComp /> : null}
    </div>
  );
};

export default ViewJobs;
